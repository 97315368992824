import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import AuthorList from './author-list'

function BlogPost(props) {
  const { _rawBody, authors, categories, title, mainImage, publishedAt } = props
  return (
    <article data-theme="journal" className={``}>
      {/* {mainImage && mainImage.asset && (
        <div className={``}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .fit('crop')
              .auto('format')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )} */}
      <Container>
        <div className={`max-w-3xl mx-auto `}>
          <div className={`pt-12 pb-24 rte `}>
            {publishedAt && (
              <div className={`rte-text`}>{format(props.publishedAt, 'MM.DD.YYYY')}</div>
            )}
            <h1 className={`pb-12 rte-text italic`}>{title}</h1>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </div>
      </Container>
    </article>
  )
}

export default BlogPost
