import React from 'react'
import { graphql, Link } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import BlogPost from '../components/blog-post'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {
  toPlainText,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import BlogPostPreviewList from '../components/blog-post-preview-list'

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: {eq: $id}) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      _rawBody(resolveReferences: {maxDepth: 5})
      
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          _rawBody
          slug {
            current
          }
        }
      }
    }
  }
`

const BlogPostTemplate = props => {
  const { data, errors } = props
  const post = data && data.post
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  const closeWindow = () => {
    if (typeof window) {
      console.log('close window')
      // setTimeout(() => {
      //   let $journal = document.getElementById('journal');
      //   if ($journal) window.scrollTo(0, $journal.offsetTop);
      // }, 10)
    }
  }
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div>
          <Link className='absolute md:fixed right-0 top-0 font-sans block text-xs tracking-widest' to='/#journal'>
            <span className='mt-2 mx-6 block'>Close</span>
          </Link>
          {errors && <SEO title='GraphQL Error' />}
          {post && <SEO title={post.title || 'Untitled'} description={toPlainText(post._rawExcerpt)} image={post.mainImage} />}

          {errors && (
            <Container>
              <GraphQLErrorList errors={errors} />
            </Container>
          )}

          <div data-theme="journal" className="min-h-screen">
            <div className="max-w-lg lg:max-w-3xl mx-auto">
              {post && <BlogPost {...post} />}
              {postNodes && (
                <div className={` mx-auto pb-24`}>
                  <BlogPostPreviewList
                    title='Latest blog posts'
                    nodes={postNodes}
                    animate={false}
                    id={post.id}
                    browseMoreHref='/archive/'
                  /></div>
              )}
            </div>
          </div>
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export default BlogPostTemplate
